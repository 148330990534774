import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  private destinations = new Subject<any>();
  private share = new Subject<any>();

  publishDestinations(data: any) {
    this.destinations.next(data);
  }

  getDestinations(): Subject<any> {
    return this.destinations;
  }

  publishShares(data?: any) {
    this.share.next(data);
  }

  getShare(): Subject<any> {
    return this.share;
  }

  destroyModals() {
    this.publishDestinations('close');
    this.publishShares('close');
  }

}
