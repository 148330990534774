// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: 'https://www.app.db.noelquebec.ca/api/',
  apiAuth: 'noelquebec',
  apiPass: 'y4n2s7V3ky',
  mapboxPK: 'pk.eyJ1Ijoic2VydmljZXNub2VscXVlYmVjIiwiYSI6ImNrdW1kNWdmeDF1bmYycG15YWE4enVsZWwifQ.5hzrikdL6bYARwoiACmBog',
  oneSignalID: '55f1478f-0aed-4028-b067-642f61fcce19',
  oneSignalSafariID: '',
  googleProjectNumber: '353898213232',
  deeplinkSplit: '',
  baseUrl: 'https://noelquebec.ca/',
  baseShareUrl: 'https://noelquebec.ca/publication/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
